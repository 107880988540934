import React from 'react'
import  css from "./Hero.module.css"
import HeroImg from "../../assets/hero.png"
import {BsArrowRight} from "react-icons/bs"
import {RiShoppingBagFill,} from "react-icons/ri"
import { motion } from "framer-motion"
function Hero() {
const transition= {duration:3,type:"spring"}

  return (
    <div className={css.container}>
        {/* left side */}
        <div className={css.h_sides}>

           <span className={css.text1}>Skin protection </span>

        <div className={css.text2}>
            <span>Trending Collection</span>
            <span>Seedily say has suitable disposal any boy . Excerise joy man chi  rekoiced.</span>
        </div>
        </div>

    {/* middel side image */}
    <div className={css.wrapper}>
        {/* blue circle  */}
        <motion.div className={css.blueCircle}
        initial={{bottom:"2rem"}}
whileInView={{bottom:"0rem"}}
transition={transition}
        ></motion.div>
        {/* hero image */}
        < motion.img
           initial={{bottom:"-2rem"}}
           whileInView={{bottom:"0rem"}}
           transition={transition}

        src={HeroImg} alt="loading" width={600} />
        <motion.div className={css.cart2}
        initial={{right:"4%"}}
        whileInView={{right:"2%"}}
        transition={transition}
        >

            <RiShoppingBagFill />

        <div className={css.signup}>
            <span>Best Signup Offers</span>
            <div>
                <BsArrowRight />
            </div>
        </div>
        </motion.div>


    </div>
    {/* right side */}
    <div className={css.h_sides}>
        <div className={css.traffic}>
            <span>1.5m</span>
            <span>Monthly traffic</span>
        </div>
        <div className={css.customers}><span>100k</span>
        <span>Happy Customers</span>
        </div>
    </div>
    </div>
  )
}

export default Hero
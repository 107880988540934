import React, { useState } from 'react'
import css from "./Header.module.css"
import Logo from "../../assets/logo.png"
import {CgShoppingBag} from "react-icons/cg"
import {GoBook} from "react-icons/go"
function Header() {
const [showMenu,setShowMenu] = useState(false)
    const toggleMenu=()=>{

setShowMenu(!showMenu)
    }
  return (
 <div className={css.container}>
{/* logo show left side*/}
    <div className={css.logo}>
        <img src={Logo} alt='logo image' />
        <span>amazon</span>
    </div>
{/* right */}
<div className={css.right}

>
    <div className={css.bars} onClick={()=>toggleMenu()}>
        <GoBook />
    </div>
    <div className={css.menu} >
        <ul
className={css.menu}
style={{display:!showMenu ?'inherit':"none"}}

        >
            <li>Collections</li>
            <li>Brands</li>
            <li>New</li>
            <li>Sales</li>
            <li>ENG</li>
        </ul>

    </div>
    <input type="text"
    placeholder='Search...'
    className={css.search} />
    <CgShoppingBag className={css.cart} />
</div>
 </div>
  )
}

export default Header
import logo from "./logo.svg";
import "./App.css";
import {
  Footer,
  Header,
  Hero,
  Products,
  Slider,
  Testimonials,
} from "./components";
import Virtual from "./components/virtual/Virtual";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <Slider />
      <Virtual />
      <Products />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default App;

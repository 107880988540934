import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './Slider.css'
import { SliderProducts } from '../../data/products'

const Slider = () => {
  return (
    <div className='s-container'>

        <Swiper
        breakpoints={{
            640:{slidesPerView:3},
            0:{
                slidesPerView:1
            }
        }}
        modules={[Pagination,Navigation]}
        className='mySwiper'
        navigation={true}
        sidesPerView={3}
        spaceBetween={40}
        slidesPerGroup={1}
        loop={true}
        >
            {SliderProducts.map((slide,i)=>(
                <SwiperSlide>
<div className='left-s'>
    <div >
        <span className='name' >{slide.name}</span>
        <span>{slide.detail}</span>

    </div>
    <div>Shop now</div>
</div>
<img src= {slide.img} alt="product" className='img-p' />

                </SwiperSlide>
            ))}
        </Swiper>
            </div>
  )
}

export default Slider
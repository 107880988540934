import React from 'react'
import css from "./Testmonials.module.css"
import Hero from '../../assets/testimonialHero.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { TestimonialsData } from '../../data/testimonials'

const Testimonials = () => {
  return (
    <div className={css.testimonials}>

        <div className={css.wrapper}>
            <div className={css.container}>
            <span>Top Rated</span>
            <span>
                Seedily say has suitable disposal and boy. Excercise joy man children rejoiced.
            </span>
            </div>


        <img src = {Hero} />
        <div className={css.container}>
<span>100k</span>
<span>Happy Customers with us</span>
        </div>
        </div>
        {/* wrapper div finish */}
        <div className={css.reviews}>
            reviews
        </div>
        <div className={css.carousel}>
            <Swiper
            slidesPerView={3}
            slidesPerGroup={1}
            spaceBetween={20}
            className={css.tCarousel}
            >
                {TestimonialsData.map((testimonial,i)=>(
                    <SwiperSlide>
                        <div className={css.testimonial}>
                            <img src={testimonial.image} />
                            <span>{testimonial.comment}</span>

                            <hr />
                            <span>{testimonial.name}</span>
                        </div>
                    </SwiperSlide>
                ))}


            </Swiper>
        </div>
    </div>
  )
}

export default Testimonials
import React from 'react'
import css from "./Footer.module.css"
import logo from '../../assets/logo.png'
import {LoginIcon,LinkIcon,UsersIcon,LocationMarkerIcon,InboxIcon,PhoneIcon,} from "@heroicons/react/outline"
const Footer = () => {
  return (
    <div className={css.cFooterWrap}>
        <hr />
        <div className={css.cFooter}>
            <div className={css.logo}>
                <img src={logo} alt='' />
                <span>amazon</span>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contact Us</span>
                    <span className={css.pngLine}>
                        <LocationMarkerIcon className={css.icon} />
                        <span>74 north avenue jaipur rajsthan 203014</span>
                    </span>
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Account </span>
                    <span className={css.pngLine}>
                        <LoginIcon className={css.icon} />
                        <span>Sign in </span>
                    </span>
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company</span>
                    <span className={css.pngLine}>
                        <PhoneIcon className={css.icon} />
                        <span>About Us</span>
                    </span>
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Resources</span>
                    <span className={css.pngLine}>
                        <LinkIcon className={css.icon} />
                        <span>Safety Privicy & Terms</span>
                    </span>
                </div>
            </div>
        </div>
        <div className={css.copyRight}>
            <span>CopyRight @2023 by amzaon inc.</span>
            <span>All rights reserved.</span>
        </div>
    </div>
  )
}

export default Footer